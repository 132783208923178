import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import { Notify } from 'vant';


import qs from 'qs'
import axios from 'axios'
import 'vant/lib/index.css'

Vue.config.productionTip = false
Vue.use(Notify);
Vue.use(Vant)

Vue.prototype.$qs = qs;
Vue.prototype.$axios = axios;
var pathname = window.location.pathname;
axios.defaults.baseURL = process.env.NODE_ENV == 'production' ? pathname : 'http://192.168.40.114:5004/';

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
